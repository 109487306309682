import React from 'react'
import './footer.scss'

export default function Footer () {
  return (
    <div id='footer'>
      <div className='footer container'>
        <p>Coded by Faith Johnson</p>
      </div>
    </div>
  )
}
